import {connect, useDispatch} from "react-redux";
import {withTranslation} from "react-i18next";
import {Col, Modal, Row} from "reactstrap";
import React, {useEffect, useState} from "react";
import {getModal} from "../../store/popap/actions";

const CentralModal = (props) =>{
    const dispatch = useDispatch();
    const [modal_center, setmodal_center] = useState(false)
    function tog_center() {
        setmodal_center(!modal_center)
    }

    const handleModal = () =>{
        localStorage.setItem("popap", false)
        localStorage.setItem("popapId", props.Popap.popapId)
        setmodal_center(!modal_center)
    }

    useEffect(()=>{
        setmodal_center(props.Popap.popap)
        dispatch(getModal())
    },[props.Popap.popap])

    function ucFirst(str) {
        if (!str) return str;
        return str[0].toUpperCase() + str.slice(1);
    }

    let ucTitle = 'popapTitle' + ucFirst(props.i18n.language)
    let ucDescription = 'popapDescription' + ucFirst(props.i18n.language)

    console.log("#########",props.Popap)
  return (
      <Row>
          <Col xl="4" md="6">
              {
                  props.Popap.status.toString() === '1' &&               <Modal
                      isOpen={modal_center}
                      centered={true}
                  >
                      <div className="modal-header">
                          <h5 className="modal-title mt-0">შეტყობინება</h5>
                          <button
                              type="button"
                              onClick={() => {
                                  handleModal()
                              }}
                              className="close"
                              data-dismiss="modal"
                              aria-label="Close"
                          >
                              <span aria-hidden="true">&times;</span>
                          </button>
                      </div>
                      <div className="modal-body">
                          <h4>
                              {props.Popap[ucTitle]}
                          </h4>
                          <p>
                              {props.Popap[ucDescription]}
                          </p>
                      </div>
                  </Modal>
              }

          </Col>

      </Row>
  )
}


const mapStateToProps = state => {
    const {
        Auth,
        Popap
    } = state
    return {
        Auth,
        Popap
    }
};

export default connect(mapStateToProps)(withTranslation()(CentralModal));
