import {
    GET_CENTRAL_MODAL_REQUEST_SUCCESS
} from "./actionTypes"

const INIT_STATE = {
    popap : false,
    popapId : '',
    popapTitleKa : '',
    popapTitleEn : '',
    popapTitleRu : '',
    popapDescriptionKa : '',
    popapDescriptionEn : '',
    popapDescriptionRu : '',
    status : '',
}

const Popap = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_CENTRAL_MODAL_REQUEST_SUCCESS:
            action.status = action.callBack.popap.status
            action.popapId = action.callBack.popap._id
            action.popapTitleKa = action.callBack.popap.titleKa
            action.popapTitleEn = action.callBack.popap.titleEn
            action.popapTitleRu = action.callBack.popap.titleRu
            action.popapDescriptionKa = action.callBack.popap.descriptionKa
            action.popapDescriptionEn = action.callBack.popap.descriptionEn
            action.popapDescriptionRu = action.callBack.popap.descriptionRu


            if (localStorage.getItem("popap")) {
                if (localStorage.getItem("popap") === 'true') {
                    state.popap = true
                }else {
                    if (localStorage.getItem("popapId") !== action.callBack.popap._id) {
                        state.popap = true
                    }else {
                        state.popap = false
                    }
                }
            }else {
                state.popap = true
                localStorage.setItem("popap", true)
            }


            return {
                ...state,
                popapId : action.callBack.popap._id,
                popapTitleKa : action.callBack.popap.titleKa,
                popapTitleEn : action.callBack.popap.titleEn,
                popapTitleRu : action.callBack.popap.titleRu,
                popapDescriptionKa : action.callBack.popap.descriptionKa,
                popapDescriptionEn : action.callBack.popap.descriptionEn,
                popapDescriptionRu : action.callBack.popap.descriptionRu,
                status : action.callBack.popap.status
            }
        default:
            return state
    }
}

export default Popap
